import { Injectable } from "@angular/core";
import { Padrao } from "src/app/model/generic/padrao";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { DadosPesquisa } from "src/app/model/system/dadosPesquisa";
import { Arquivo } from "src/app/model/importacao/arquivo";
import { ConstantsAero } from "src/app/demo/components/aerosys/generic/constantsAero";
import { AppConfig } from "src/app.config";

@Injectable({
  providedIn: 'root'
})

export class GenericService<T extends Padrao> {

  protected constantsAero: ConstantsAero = new ConstantsAero();

  url: string;
  resource: string;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };
  httpOptionsPdf = {
    headers: new HttpHeaders({ 'Content-Type': 'application/pdf', })
  };

  constructor(protected http: HttpClient) {
  }

  getHttpClient() {
    return this.http;
  }

  initResource(resource: string) {
    this.resource = resource;
    this.url = AppConfig.urlBasic + this.resource;
  }

  findAll(idCompany: number): Observable<T[]> {
    const urlLocal = `${this.url}/${idCompany}`;
    return this.http.get<T[]>(urlLocal);
  }

  findFilterAll(idCompany: number, dadosPesquisa: DadosPesquisa, loadItems: boolean): Observable<T[]> {
    const urlLocal = `${this.url}/list/${idCompany}/false`;
    console.log(dadosPesquisa);
    console.log(urlLocal);
    return this.http.post<T[]>(urlLocal, dadosPesquisa, this.httpOptions);
  }

  findFilterAllDuplo(idCompany: number, dadosPesquisa: DadosPesquisa, loadItems: boolean): Observable<T[]> {
    const urlLocal = `${this.url}/list/items/${idCompany}/${loadItems}`;
    return this.http.post<T[]>(urlLocal, dadosPesquisa, this.httpOptions);
  }

  findById(idCompany: number, id: number): Observable<T> {
    const urlLocal = `${this.url}/${idCompany}/${id}`;
    //console.log('URL Local:', urlLocal); // Log da URL local

    let obj = this.http.get<T>(urlLocal);
    //console.log('Objeto HTTP:', obj); // Log do objeto HTTP

    obj.subscribe(res => {
      let x = res;
      //console.log('Resposta HTTP:', x); // Log da resposta HTTP

      Object.getOwnPropertyNames(x).forEach(function (val, idx, array) {
        //console.log(val + ' -> ' + x[val]);
        if (val === 'dataCad') {
          x[val] = new Date(x[val]);
        }
      });
    });

    return obj;
  }

  findByIdDuplo(idCompany: number, id: number): Observable<T> {
    const urlLocal = `${this.url}/duplo/${idCompany}/${id}`;
    let obj = this.http.get<T>(urlLocal);

    obj.subscribe(res => {
      //console.log("Dados recebidos do servidor:", res); // Loga todos os dados recebidos
      Object.getOwnPropertyNames(res).forEach(val => {
        //console.log(val + ' -> ' + res[val]); // Loga cada propriedade e seu valor
        if (val === 'dataCad' && res[val]) {
          res[val] = new Date(res[val]); // Converte 'dataCad' para objeto Date, se existir
        }
      });
    });

    return obj;
  }

  insert(obj: T): Observable<T> {
    return this.http.post<T>(this.url, obj, this.httpOptions);
  }

  insertMult(obj: T, files: any[]): Observable<T> {
    const urlLocal = `${this.url}/mult`;

    const formData = new FormData();
    for (let file of files) {
      formData.append('upload', file);
    }
    formData.append('jsonObject', JSON.stringify(obj));

    return this.http.post<T>(urlLocal, formData);
  }

  update(id: number, obj: T): Observable<T> {
    const urlLocal = `${this.url}/${id}`;
    return this.http.put<T>(urlLocal, obj, this.httpOptions);
  }

  updateParcial(idCompany: number, id: number, propriedade: string, valor: string, idRegra: number): Observable<T> {
    const urlLocal = `${this.url}/parcial/${idCompany}/${id}/${propriedade}/${valor}/${idRegra}`;
    return this.http.patch<T>(urlLocal, this.httpOptions);
  }

  //Novo
  updateParcialMult(idEmpresa: number, id: number, camposParaAtualizar: { [key: string]: string }, idRegra: number): Observable<any> {
    const url = `${this.url}/parcialMult/${idEmpresa}/${id}/${idRegra}`;
    return this.http.patch(url, camposParaAtualizar);
  }

  //Novo
  updateParcialItens(empresaId: number, itemId: number, propriedade: string, valor: string, idRegra: number, entidade: string): Observable<any> {
    const url = `${this.url}/${entidade}/parcial/${empresaId}/${itemId}/${propriedade}/${valor}/${idRegra}`;
    return this.http.patch(url, {});
  }

  //Novo
  updateItensParcial(baseUrl: string, idCompany: number, ids: number[], propriedade: string, valor: string, idRegra: number): Observable<T> {
    // Constrói a URL dinamicamente com base no prefixo
    const urlLocal = `${baseUrl}/itens/parcial/${idCompany}/${ids.join(',')}/${propriedade}/${valor}/${idRegra}`;
    return this.http.patch<T>(urlLocal, this.httpOptions);
  }

  delete(idCompany: number, id: number): Observable<T> {
    const urlLocal = `${this.url}/${idCompany}/${id}`;
    return this.http.delete<T>(urlLocal, this.httpOptions);
  }

  // Methods Reports

  generateReport(idCompany: number,) {
    const urlLocal = `${this.url}/report/${idCompany}/pdf`;
    return this.http.get(urlLocal, { responseType: 'blob' })
      .toPromise();
  }

  // Methods Files

  uploadFiles(event, id: number, infoExtra: string[]) {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    const urlLocal = `${this.url}/upload/${id}/${infoExtra}`;
    //console.log(urlLocal);

    const formData = new FormData();
    for (let file of event.files) {
      formData.append('upload', file);
    }

    return this.http.post(`${urlLocal}`, formData)
      .toPromise()
      .then
      ((res: any) => {
        return true;
      })
      .catch((res: any) => {
        return false;
      });
  }

  listFiles(id: number, infoExtra: string[]): Observable<Arquivo[]> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    const urlLocal = `${this.url}/download/${id}/${infoExtra.toString()}`;
    //console.log(urlLocal);

    return this.http.get<Arquivo[]>(urlLocal);
  }

  downloadFile(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.get(`${this.url}/downloadFile/${id}/${fileName}/${infoExtra.toString()}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

  downloadImage(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.get(`${this.url}/downloadImage/${id}/${fileName}/${infoExtra.toString()}`, { responseType: 'blob' });
  }

  downloadPdf(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.get(`${this.url}/downloadPdf/${id}/${fileName}/${infoExtra.toString()}`, { responseType: 'blob' });
  }

  deleteFile(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.delete(`${this.url}/deleteFile/${id}/${fileName}/${infoExtra.toString()}`);
  }

  // Methods Security

  getPermissao(idCompany: number, idRegra: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/permission/${idCompany}/${idRegra.toString()}`)
  }

  returnValueRule(idCompany: number, idRegra: number): Observable<string> {
    return this.http.get(`${this.url}/rulevalue/${idCompany}/${idRegra.toString()}`, { responseType: 'text' });
  }

  returnValueRuleText(idCompany: number, idRegra: number): Observable<string> {
    return this.http.get(`${this.url}/rulevalue/${idCompany}/${idRegra.toString()}`, { responseType: 'text' });
  }


  // Private Methods

  private verifyAndReturnInfoExtra(infoExtra: string[]): string[] {
    if ((infoExtra) && (infoExtra.length > 0))
      return infoExtra
    else
      return ['0'];
  }

}    