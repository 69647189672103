<div class="card p-1 m-0 pb-2">

    <div class="grid m-0 p-0">
        <div class="col-12 m-0 p-0 field">
            <div class="block text-center h-3rem font-bold border-round m-0 p-2" [ngStyle]="{'backgroundColor':auth.getCorEmpresaSel()}">
                <h5>{{ this.title }}</h5>
            </div>
        </div>
    </div>

    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

    <p-table #dt id="dt" [columns]="cols" [value]="list" [resizableColumns]="true"
        [rowHover]="true" [(selection)]="listSel" [selectionMode]="multCheck ? '' : 'single'"  dataKey="id" (onRowSelect)="onRowSelectObj($event)"
        [paginator]="true" [rows]="10" stateStorage="local" stateKey="dtId" 
        currentPageReportTemplate="{{'title_total_records' | transloco }} {totalRecords}."
        styleClass="p-datatable-striped p-datatable-sm"
        responsiveLayout="stack" [breakpoint]="'768px'">

        <ng-template pTemplate="caption">
            <div class="grid p-fluid">
                <!--
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" #filter (input)="onGlobalFilter(dt, $event)" placeholder=" {{'title_search' | transloco }} " class="w-full"/>
                </span>
                -->

                <div class="col-12 md:col-9">
                    <div class="grid m-0 p-0">
                        <div class="col-12 md:col-2 m-0 p-0 pr-1 field">
                            <p-dropdown id="inputFiltroCampo" name="inputFiltroCampo" [(ngModel)]="dadosPesquisa.campo" 
                            [options]="colsFilter" autoWidth="false" emptyMessage="">
                            </p-dropdown>
                        </div>
                        <div class="col-12 md:col-3 m-0 p-0 pr-1 field">
                            <input pInputText id="inputFiltro" type="text" [(ngModel)]="dadosPesquisa.valor" (keydown.enter)="find()" placeholder=" {{'title_search' | transloco }} "/>
                        </div>

                        <div class="col-12 md:col-2 m-0 p-0 pr-1 field" *ngIf="colsDataFilter.length > 1">
                            <p-dropdown id="inputFiltroCampoData" name="inputFiltroCampoData" [(ngModel)]="dadosPesquisa.campoData" 
                            [options]="colsDataFilter" autoWidth="false" emptyMessage="" [autoDisplayFirst]="false">
                            </p-dropdown>
                        </div>
                        <div class="col-12 md:col-4 m-0 p-0 pr-1 field" *ngIf="colsDataFilter.length > 1">
                            <div class="grid m-0 p-0">
                                <div class="col-12 md:col-6 m-0 p-0 pr-1 field">
                                    <p-calendar dateMask id="inputFiltroDataIni" name="inputFiltroDataIni" [(ngModel)]="dadosPesquisa.dataInicial" dateFormat="{{ 'date_short_format_form' | transloco }}" [showIcon]="true"></p-calendar>
                                </div>
                                <div class="col-12 md:col-6 m-0 p-0 pr-1 field">
                                    <p-calendar dateMask id="inputFiltroDataFim" name="inputFiltroDataFim" [(ngModel)]="dadosPesquisa.dataFinal" dateFormat="{{ 'date_short_format_form' | transloco }}" [showIcon]="true"></p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-11 m-0 p-0 pr-1 pt-1 field" *ngIf="viewFiltro">
                            <div class="grid m-0 p-0">
                                <div *ngFor="let sysCampo of filtrosExtra; let iCol = index" class="col-12 md:col-4 m-0 p-0 pr-1 field">
                                    <label>{{constantsAero.PREFIX_FIELD + sysCampo.id | transloco }}</label>
                                    <p-multiSelect [options]="sysCampo.listaItens" [(ngModel)]="filtroExtraSel[iCol]" display="chip" [showClear]="true"></p-multiSelect>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-1 m-0 p-0">
                            <p-splitButton icon="pi pi-search" (onClick)="find()" [model]="this.itemsPesqFiltro"></p-splitButton>
                        </div>
                    </div>
                </div>


                <div class="col-12 md:col-3">
                    <!--
                    <button *ngIf="acessosGrupo.filtrar == 'Sim'" pButton title="{{'button_export' | transloco }}" class="buttonMargin" (click)="dt.exportCSV({selectionOnly:true})"></button>
                    -->
                    <p-tieredMenu  id="menuFunctions" #menuFunctions [popup]="true" [model]="itemsFunctions" appendTo = "body" ></p-tieredMenu>
                    <button type="button" pButton title="{{'button_functions' | transloco }}" icon="pi pi-fw pi-cog"   (click)="menuFunctions.toggle($event)" class="buttonMargin" style="float: right"></button>

                    <p-tieredMenu  id="menuPrint"     #menuPrint     [popup]="true" [model]="itemsPrint"   style="width: 300px;"  appendTo = "body"></p-tieredMenu>
                    <button type="button" pButton title="{{'button_print' | transloco }}"     icon="pi pi-fw pi-print" (click)="menuPrint.toggle($event)" class="buttonMargin" style="float: right"></button>

                    <button *ngIf="acessosGrupo.inserir == 'Sim'" pButton title="{{'button_insert' | transloco }}" class="buttonMargin" style="float: right" icon="pi pi-fw pi-plus" (click)="openNewForm()"></button>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pResizableColumn
                *ngIf="acessosGrupo.visualizar == 'Sim' || acessosGrupo.alterar == 'Sim' || acessosGrupo.excluir == 'Sim'"
                >
                </th>
                <th *ngIf="multCheck" pResizableColumn><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                <th pResizableColumn>Ord</th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn>
                    {{col.header | transloco }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" let-iLin="rowIndex">
            <tr [pSelectableRow]="rowData" (click)="selectObj(rowData)">
                <td  style="min-width:20px; width:20px" *ngIf="acessosGrupo.visualizar == 'Sim' || acessosGrupo.alterar == 'Sim' || acessosGrupo.excluir == 'Sim'">
                    <p-tieredMenu  id="menuFunctionsItems" #menuFunctionsItems [popup]="true" [model]="itemsFunctionsItems" appendTo = "body"></p-tieredMenu>
                    <button pButton type="button" icon="pi pi-cog" class="p-button-outlined buttonRow" title="{{ 'button_functions_items' | transloco }}" (click)="selectObj(rowData); menuFunctionsItems.toggle($event)"></button>
                </td>
                <td *ngIf="multCheck" style="min-width:20px; width:20px">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>                    
                <td  style="min-width:30px; width:30px">{{iLin+1 | number:'3.0-0'}}</td>
                <td *ngFor="let col of columns; let iCol = index" style="width: col.widthList px" [ngStyle]="{'backgroundColor':getColorCell(rowData[col.field])}" >
                    <span *ngIf="col.type == 'N° Inteiro' && col.field == 'id'" style="float: center">{{ globalFunctions.getIdFormat(rowData[col.field]) }}</span>                        
                    <span *ngIf="col.type == 'N° Inteiro' && col.field != 'id' && col.type != 'Badge'" style="float: center">{{ rowData[col.field] }}</span>                        
                    <span *ngIf="col.type == 'Badge'" style="float: center"><p-badge severity="{{getStyleBagde(rowData[col.field])}}" [value]="rowData[col.field]"></p-badge></span>                        
                    <span *ngIf="col.type == 'N° Real'" style="float: right">{{ globalFunctions.getFormatNumber(rowData[col.field]) }}</span>
                    <span *ngIf="col.type == 'Data'" style="float: center">{{ rowData[col.field] | date: globalFunctions.getDataFormat() }}</span>                        
                    <span *ngIf="col.type == 'DataHora'" style="float: center">{{ rowData[col.field] | date: globalFunctions.getDataHoraFormat() }}</span> 
                    <span *ngIf="col.type == 'Obj' || col.type == 'Rel'">{{rowData[col.field][col.fieldView]}}</span>                        
                    <span *ngIf="col.field != 'id' && col.type != 'N° Real' && col.type != 'N° Inteiro' && col.type != 'Data' && col.type != 'DataHora' && col.type != 'Obj' && col.type != 'Rel' && col.type != 'Badge'">
                        {{rowData[col.field]}} 
                    </span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer" let-columns>
            <tr>
                <td *ngIf="!multCheck" colspan="2">{{ 'title_total_records' | transloco }} {{ globalFunctions.getTotalRecords(list) }}</td>
                <td *ngIf="multCheck" colspan="3">{{ 'title_total_records' | transloco }} {{ globalFunctions.getTotalRecords(list) }}</td>
                <td *ngFor="let col of columns">
                    <span *ngIf="col.type == 'N° Real'" style="float: right">{{ globalFunctions.getTotalColuna(col.field, list, undefined) }}</span>
                </td>
            </tr>
        </ng-template>        

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" style="text-align:left">{{'title_no_records' | transloco }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>